export default {
  data() {
    return {
      encounterDefaults: {
        visit_type: null,
        visit_location: 'In-person',
        place_of_service_id: null,
        practice_type_id: this.$route.params.practiceTypeId || 1,
        visit_date: this.$date().format('YYYY-MM-DD'),
        is_validated: false,
        is_signed: false,
        is_synced: false,
        is_quick_entry: false,
        is_deferred_care: false,
        deferred_care_reason: null,
        is_patient_refused_vitals: false,

        // Not in the DB, for Vue only
        can_be_deleted: true,
      },
      encounter: {
        ...this.encounterDefaults,
      },

    }
  },
  computed: {
    clearFollowup() {
      return {
        // These fields/sections are cleared for follow-up visits
        addendums: [],
        recently_hospitalized: null,
        health_change: null,
        health_change_other: null,
        temperature: null,
        temperature_scale: null,
        heart_rate: null,
        bp_systolic: null,
        bp_diastolic: null,
        respiratory_rate: null,
        encounter_pulse_assessment: {},
        dfu_risk: null,
        dfu_risk_reason: null,
        footwear_evaluation: null,
        footwear_evaluation_reason: null,
        footwear_exam_reason: null,
        footwear_notable_issues: [],
        a1c_level: null,
        encounter_active_wound_infection: {},
        labs: [],
        labs_other: null,
        labs_order_date: null,
        visit_reason: null,
        visit_reason_add_to_note: false,
        is_quick_entry: this.isQuickEntry,
        is_quick_entry_validated: true,
        quick_entry_room: null,
        quick_entry_notes: null,
        quick_entry_notes_to_nurse: null,
        communication_log_id: null,
        communication_log: {},
        general_appearances: [],
        general_appearances_other: null,
        therapeutic_anticoagulant: null,
        mental_status: null,
        assessment_plan: null,
        body_areas: [
          { id: 1, title: 'Head & Face' },
          { id: 2, title: 'Scalp' },
          { id: 3, title: 'Neck' },
          { id: 4, title: 'Back' },
          { id: 9, title: 'Buttocks' },
          { id: 10, title: 'Sacrum' },
          { id: 11, title: 'Coccyx' },
          { id: 12, title: 'Left Ischium' },
          { id: 13, title: 'Right Ischium' },
          { id: 14, title: 'Left Trochanter' },
          { id: 15, title: 'Right Trochanter' },
          { id: 16, title: 'Left Upper Extr' },
          { id: 17, title: 'Right Upper Extr' },
          { id: 18, title: 'Left Lower Extr' },
          { id: 19, title: 'Right Lower Extr' },
          { id: 20, title: 'Left Foot' },
          { id: 21, title: 'Right Foot' },
        ],
        body_areas_notes: null,
        payroll_batch_id: null,
        is_payroll_ignore: 0,
        estimated_payment: null,
        is_revised: 0,
        fax_status_id: 0,
        fax_fail_count: 0,
        pcc_status_id: 0,
        is_manual_upload: 0,
        final_note: null,
        is_imported: 0,
        import_id: null,
        is_reviewed: 0,
        reviewed_time: null,
        reviewed_by_user_id: null,
        signed_by_user_id: null,
        signed_time: null,
      }
    },
    clearPsychology() {
      return {
        appearance: null,
        behaviors: [],
        speeches: [],
        orientation: null,
        memory: null,
        intellect: null,
        concentration: null,
        thought_flows: [],
        insight: null,
        moods: [],
        affects: [],
        hallucinations: null,
        hallucinations_yes: null,
        delusions: null,
        delusions_yes: null,
        homicidal: null,
        homicidal_yes: null,
        suicidal: null,
        suicidal_yes: null,
        judgement: null,
        diagnosis: null,
        previous_diagnosis: null,
        diagnostic_impression: null,
        strengths: [],
        strengths_other: null,
        limitations: [],
        limitations_other: null,
        impaired_functions: [],
        impaired_functions_other: null,
        recommendations: null,
        frequency_of_follow_up_visits: null,
        estimated_duration: null,
        prognosis: null,
        treatment_modification_required_for: null,
        treatment_modification_required_for_yes: null,
      }
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    generateEncounter(previousEncounter, patient = this.patient) {
      // Create the new encounter
      this.generateCoreEncounter(previousEncounter, patient).then(e => {
        // Set the newly created encounter ID
        this.$store.commit('encounters/addEncounter', this.encounter)
        this.encounter.id = this.$store.getters['encounters/getLastId']

        // If the facility is different and there was a facility acquired wound, remove values
        this.$store.commit('patients/updateFacilityAcquiredWound', {
          id: this.encounter.patient_id,
          facility: this.encounter.place_of_service_id,
        })
      })

      return new Promise(resolve => resolve(true))
    },

    // NOTE: This encounter does not generate an ID for the encounter it generates, the ID it settled when the encounter is committed to the store
    generateCoreEncounter(previousEncounter, patient = this.patient) {
      // Add some required values to the encounter object
      this.encounter.patient_id = patient.id

      // Set pay_tier_id
      this.encounter.pay_tier_id = this.$authUser.profile().pay_tier

      if (this.isQuickEntry && this.encounter.practice_type_id === 1) {
        // Whenever we add a new encounter level field in QENT, we have to initialize it here.
        this.encounter.quick_entry_room = null
        this.encounter.quick_entry_notes = null
        this.encounter.quick_entry_notes_to_nurse = null
        this.encounter.labs = []
        this.encounter.labs_order_date = null
        this.encounter.labs_other = null
      }

      // If follow-up visit, pull data from previous encounter
      if (previousEncounter !== false) {
        // Wound care
        if (this.encounter.practice_type_id === 1) {
          this.encounter = {
            ...this.$lodash.cloneDeep(previousEncounter),
            ...this.encounter,
            ...this.clearFollowup,
          }
        }

        // Psychology
        if (this.encounter.practice_type_id === 3) {
          this.encounter = {
            ...this.$lodash.cloneDeep(previousEncounter),
            ...this.encounter,

            // These fields/sections are cleared for follow-up visits
            psychology: {
              ...this.$lodash.cloneDeep(previousEncounter.psychology),
              ...this.clearPsychology,
            },
          }
        }
      }

      // If deferred care, validate encounter
      if (this.encounter.is_deferred_care) {
        this.encounter.is_validated = true
      }

      return new Promise(resolve => resolve(this.encounter))
    },

    // Sets properties for use in the create.vue component
    primeEncounter() {
      // return this.encounter to default values
      this.encounter = this.encounterDefaults

      this.encounter = {
        ...this.encounter,
        created_by_user_id: this.$authUser.id(),
        place_of_service_id: this.$route.params.placeOfServiceId || this.patient.place_of_service_id,
        created: this.$custom.utcNow(),
        modified: this.$custom.utcNow(),
      }
    },
    selectVisitType(previousEncounter) {
      // const previousEncounter = this.$store.getters['encounters/previousEncounterId'](this.patient.id, this.encounter.practice_type_id, this.encounter.visit_date)
      if (!this.encounter.practice_type_id) {
        this.encounter.visit_type = null
      } else if (previousEncounter === false) {
        this.encounter.visit_type = 'New'
      } else {
        this.encounter.visit_type = 'Follow-up'
      }
      this.encounter.is_quick_entry = this.isQuickEntry
    },
  },
}
