<template>
  <v-card
    class="col-12 pa-0 ma-0"
  >
    <v-col
      class="sticky-header ma-0 pa-0 overflow-x-hidden"
    >
      <v-card-title class="med-x">
        <label>
          Med Ex
        </label>
        <v-spacer></v-spacer>
        <slot name="header">
        </slot>
      </v-card-title>
      <v-col
        class="pr-10 mx-5 d-flex justify-space-between"
      >
        <label>
          {{ numSelectedEncounters() }} items selected
        </label>

        <btn
          :disabled="preGenBtnHandler()"
          label="Generate"
          :icon="icons.mdiLightningBolt"
          color="primary"
          @click="startPreGen()"
        >
        </btn>
      </v-col>
    </v-col>

    <v-col
      v-for="(facility, index) in userFacilities"
      :key="facility.id"
      class="rounded-xl col-12 pt-10"
      elevation="24"
    >
      <v-card
        outlined
        class=" mx-2 mb-5 elevation-3"
      >
        <v-card-title
          class="facility-title ma-0"
        >
          <label>{{ $store.getters['facilities/getById'](facility.id).title }}</label>
        </v-card-title>
        <v-data-table
          :ref="`table${index}`"
          :headers="preGenHeaders"
          :items="preGenPrevEncounters[facility.id]"
          item-key="id"
          :page.sync="preGenPagination.page[index]"
          :items-per-page="preGenPagination.itemsPerPage[index]"
          :sort-by.sync="sort.by[index] || 'visit_date'"
          :sort-desc.sync="sort.desc[index] || true"
          hide-default-footer
          show-select
          class="has-pagination col-12 mb-5 pa-0"
          @page-count="preGenPagination.pageCount.splice(index, 1, $event)"
        >
          <template #header.data-table-select>
            <v-checkbox
              v-model="preGenSelectAllStatus[index]"
              :disabled="!preGenPrevEncounters[facility.id]"
              :indeterminate="preGenSelectAllIndeterminate[index]"
              :off-icon="icons.mdiCheckboxMultipleBlankOutline"
              :on-icon="icons.mdiCheckboxMultipleMarked"
              :indeterminate-icon="icons.mdiMinusBoxMultiple"
              hide-details
              color="secondary"
              @click="preGenTableSelectAllToggle(index, facility.id)"
            ></v-checkbox>
          </template>

          <template #item.dob="{ item }">
            {{ $date($store.getters['patients/getById'](item.patient_id).dob).format('MM/DD/YYYY') }}
          </template>
          <template #item.visit_date="{ item }">
            {{ $date(item.visit_date).format('MM/DD/YYYY') }}
          </template>

          <!-- table data overrides -->
          <template
            #item.data-table-select="{ item }"
            :ref="`rowSelection${index}`"
          >
            <v-checkbox
              v-model="preGenSelected[index].encounters"
              :value="item"
              hide-details
              color="secondary"
              @click.stop="preGenUpdateSelectStatus(index, facility.id)"
            ></v-checkbox>
          </template>
        </v-data-table>

        <pagination
          :page.sync="preGenPagination.page[index]"
          :items-per-page.sync="preGenPagination.itemsPerPage[index]"
          :page-count="preGenPagination.pageCount[index]"
          :items-per-page-list="preGenPagination.itemsPerPageList[index]"
        ></pagination>
      </v-card>
    </v-col>

    <v-card-actions>
      <slot name="footer">
      </slot>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import '@/components/patients/primitives'
import EncounterCreation from '@/mixins/EncounterCreation'
import {
  mdiCheckboxMultipleBlankOutline,
  mdiCheckboxMultipleMarked,
  mdiClose,
  mdiLightningBolt,
  mdiMinusBoxMultiple,
} from '@mdi/js'

import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

// import { v4 as uuidv4 } from 'uuid'

const preGenPaginationDefaults = {
  page: 1,
  pageCount: 0,
  itemsPerPage: 5,
  itemsPerPageList: [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '25', value: 25 },
  ],
}

export default {
  components: { },
  mixins: [EncounterCreation],
  props: {

  },
  data() {
    return {
      preGenPrevEncounters: [],
      loading: false,
      icons: {
        mdiClose,
        mdiCheckboxMultipleBlankOutline,
        mdiCheckboxMultipleMarked,
        mdiMinusBoxMultiple,
        mdiLightningBolt,
      },
      preGenSelectAllStatus: [],
      preGenSelectAllIndeterminate: [],
      expanded: [],
      preGenHeaders: [
        {
          text: 'First',
          value: 'first_name',
        },
        {
          text: 'Last',
          value: 'last_name',
        },
        {
          text: 'Date of birth',
          value: 'dob',
        },
        {
          text: 'Last Visit',
          value: 'visit_date',
        },
        {
          text: 'Type',
          value: 'visit_type',
          align: 'small',
        },
      ],
      preGenFacilityHeader: [
        {
          text: 'Facilities',
          align: 'center',
          value: 'id',
        },
      ],
      preGenPagination: {
        page: [],
        pageCount: [],
        itemsPerPage: [],
        itemsPerPageList: [],
      },
      sort: {
        by: [],
        desc: [],
      },
      userId: this.$authUser.userId(),
      preGenSelected: {},
    }
  },
  computed: {
    ...mapFields(['online']),
    ...mapState('encounters', { encounters: 'items' }),
    ...mapGetters('auth', { userFacilities: 'facilities' }),
  },
  watch: {
  },
  beforeMount() {
    this.generateRecs()
  },
  created() {
    this.preGenSelected = this.userFacilities.map(facility => ({ id: facility.id, encounters: [] }))
    Object.keys(this.preGenPagination).forEach(key => {
      this.userFacilities.forEach((facility, index) => {
        this.preGenPagination[key].push(preGenPaginationDefaults[key])
      })
    })
  },
  methods: {
    generateRecs() {
      const today = dayjs().startOf('day') // Current date at 12AM
      const weeksAgo = 3 // Configurable number of weeks
      const lowerThreshold = today.clone().subtract(weeksAgo, 'weeks').startOf('isoWeek') // Monday of the week 'x' weeks ago
      const upperThreshold = today.clone().startOf('isoWeek').subtract(1, 'day') // Sunday before the current isoWeek
      const currentIsoWeek = today.clone().startOf('isoWeek') // Get current isoWeek once

      // Precompute encounters for the current week
      const encountersThisWeek = new Set(
        this.encounters
          .filter(existingEncounter => existingEncounter.created_by_user_id === this.userId
            && existingEncounter.practice_type_id === 1 // Quick entry only applies to wound care
            && !existingEncounter.deleted
            && dayjs(existingEncounter.visit_date).startOf('isoWeek').isSame(currentIsoWeek, 'day'))
          .map(e => e.patient_id), // Create a Set of patient IDs to check quickly
      )

      // TXP-435: Optimize encounter filtering, sorting, and reduction
      this.preGenPrevEncounters = this.encounters
        .map(e => ({
          id: e.id,
          patient_id: e.patient_id,
          visit_date: e.visit_date,
          created_by_user_id: e.created_by_user_id,
          deleted: e.deleted,
          practice_type_id: e.practice_type_id,
          place_of_service_id: e.place_of_service_id,
          visit_type: e.visit_type,
        })) // Only keep the relevant fields
        .filter(e => {
          const dos = dayjs(e.visit_date).startOf('day')
          const isWithinRange = dos.isBetween(lowerThreshold, upperThreshold, 'day', '[]')

          return (
            e.created_by_user_id === this.userId
            && e.practice_type_id === 1 // Quick entry only applies to wound care
            && !e.deleted // Don't include synced notes that were just deleted
            && isWithinRange
            && !encountersThisWeek.has(e.patient_id) // Don't include if there is already an encounter this week
          )
        })
        .reduce((facilities, item) => {
          if (!facilities[item.place_of_service_id]) {
            facilities[item.place_of_service_id] = []
          }

          // Find existing encounter for the same patient in the same facility
          const existingPatientIndex = facilities[item.place_of_service_id].findIndex(encounter => encounter.patient_id === item.patient_id)

          // If encounter exists, update it if the new one has a later visit date
          if (existingPatientIndex !== -1) {
            if (dayjs(item.visit_date).isAfter(facilities[item.place_of_service_id][existingPatientIndex].visit_date)) {
              facilities[item.place_of_service_id][existingPatientIndex] = item
            }
          } else {
            // If no existing encounter, add the current one
            facilities[item.place_of_service_id].push(item)
          }

          // Add patient names here
          const patientInfo = this.$store.getters['patients/getById'](item.patient_id)
          item.first_name = patientInfo.first_name
          item.last_name = patientInfo.last_name

          return facilities
        }, {})
    },

    //
    preGenTableSelectAllToggle(index, facilityId) {
      if (this.preGenSelectAllStatus[index] && !this.preGenSelectAllIndeterminate[index]) {
        this.preGenSelected[index].encounters = this.preGenPrevEncounters[facilityId]
      } else {
        this.preGenSelected[index].encounters = []
      }
      this.$nextTick(() => {
        this.preGenUpdateSelectStatus(index, facilityId)
      })
    },
    preGenUpdateSelectStatus(index, facilityId) {
      this.preGenSelectAllIndeterminate[index] = !!this.preGenSelected[index].encounters.length && this.preGenSelected[index].encounters.length !== this.preGenPrevEncounters[facilityId].length
      this.preGenSelectAllIndeterminate = [...this.preGenSelectAllIndeterminate]
      this.preGenSelectAllStatus[index] = this.preGenSelected[index].encounters.length !== 0 && !this.preGenSelectAllIndeterminate[index]
    },
    preGenBtnHandler() {
      return ![...this.preGenSelected]
        .find(e => e.encounters.length >= 1)
    },
    async startPreGen() {
      if (this.preGenSelected.length !== 0) {
        // Trigger loading screen process
        this.$store.dispatch('loading', true)

        this.isQuickEntry = true
        let numGenerated = 0
        const selectedItems = this.preGenSelected
        const encountersArr = []
        const patientsArr = []

        // Loop through the facilities and their encounters
        for (const facility of selectedItems) {
          for (const encounter of facility.encounters) {
            // Load the complete data for the related patient and encounters
            await this.$store.dispatch('patients/loadPatientData', encounter.patient_id)
            await this.$store.dispatch('encounters/loadEncounterData', encounter.patient_id)

            this.patient = this.$store.getters['patients/getById'](encounter.patient_id)
            const fullPreviousEncounter = this.$store.getters['encounters/getById'](encounter.id)

            this.primeEncounter()
            this.selectVisitType(fullPreviousEncounter)

            // Generate encounter
            await this.generateCoreEncounter(fullPreviousEncounter).then(e => {
              // generateCoreEncounter does not generate a UUID for the encounter
              encountersArr.push({ ...e, id: uuidv4() })
              patientsArr.push({
                id: e.patient_id,
                facility: e.place_of_service_id,
              })
            })
            numGenerated += 1
          }
        }

        // Commit generated encounters to store
        this.$store.commit('encounters/addEncounters', encountersArr)

        // Update progress bar.
        this.$store.dispatch('loading', false)

        // Confirmation dialog after all encounters have been generated
        this.$root.confirm({
          titleIconColor: 'success',
          title: 'Quick Entry Notes Successfully generated',
          body: `Number of quick entry notes generated: ${numGenerated}`,
          html: true,
          cancel: false,
          persistent: true,
          confirmIcon: this.icons.mdiSync,
          confirm: 'Okay',
        }).then(() => {
          // Route change after the confirmation dialog to refresh quick entry
          this.$router.replace({
            name: 'quick-entry',
            query: {
              visit_date: dayjs().format('YYYY-MM-DD'),
              pageKey: Date.now(),
            },
          })
        })
      } else {
        this.errorDialog('Please select at least one patient to generate an encounter for!')
      }
    },
    numSelectedEncounters() {
      let num = 0

      this.preGenSelected.forEach(facility => {
        num += facility.encounters.length
      })

      return num
    },
  },
}
</script>

<style lang="scss">
.med-x {
    padding: 0 !important;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    label {
      font-size: 32px;
      font-weight: 600;
      color: var(--v-secondary-darken3) !important;
      line-height: 2;
      padding: 0;
    }
  }
.facility-title {
    display: block;
    text-align: center;
    padding: 0 !important;
    margin-top: 0;
    margin-bottom: 20px;
    background-color: rgb(251, 251, 251) !important;
    label {
      font-size: 16px;
      font-weight: 600;
      color: var(--v-secondary-darken3) !important;
      line-height: 2;
      padding: 0;
    }
  }
</style>
